<template>
    <div class="categories-container">
        <p class="concept_description">{{ lang.company['select_concept_description'] }}</p>
        
        <div v-for="category in sortedCategories" :key="category.id" class="category-accordion">

            <div class="category-header" @click="toggleCategory(category.id)">
                <h3 class="">{{ safeJsonParse(category.name)[lang.name] ? safeJsonParse(category.name)[lang.name] : "-" }}</h3>
                <i :class="openCategories[category.id] ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
            </div>
            <div class="category-content" v-show="openCategories[category.id]">
                <div v-if="category.elements && category.elements.length" class="concepts-grid">
                    <Concept
                        v-for="(concept, index) in category.elements" 
                        :key="concept.id"
                        :concept="concept"
                        :isLastItem="index == category.elements.length-1"
                        @conceptSelected="conceptSelected"
                        @eventSelected="eventSelected"
                    />
                </div>
            </div>
        </div>

        <!-- Uncategorized section -->
        <div v-if="uncategorizedConcepts.length" class="uncategorized-section">
            <div class="concepts-grid">
                <Concept
                    v-for="(concept, index) in uncategorizedConcepts" 
                    :key="concept.id"
                    :concept="concept"
                    :isLastItem="index == uncategorizedConcepts.length-1"
                    @conceptSelected="conceptSelected"
                    @eventSelected="eventSelected"
                ></Concept>
            </div>
        </div>

        <span  v-if="removeDefaultBookingBtn(data) == 0"
            class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only continue_regular_btn" 
            role="button" 
            aria-disabled="false"
            @click="conceptSelected(0, lang.company['no_concept_selected'])"
        >
            <span class="ui-button-text">{{ lang.company['continue_normal_booking'] }}</span> 
        </span> 
    </div>
</template>

<script lang="js">

import Concept from '../Concept.vue'

import HelperFunctions from '@/mixins/HelperFunctions.js'

export default {
    name: 'SelectConceptComponent',
    data() {
        return {
            openCategories: {}
        }
    },
    props: ['data', 'dataSelected', 'restaurantData'],
    mixins: [HelperFunctions],
    components: {
        Concept
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        sortedCategories() {
            if (!this.data.hasOwnProperty("categorized")) return []
            return [...this.data.categorized]
                .filter(category => category.elements && category.elements.length > 0)
                .sort((a, b) => a.rank - b.rank)
        },
        uncategorizedConcepts() {
            return this.data.uncategorized?.elements || []
        }
    },
    methods: {
        removeDefaultBookingBtn(data) {
            const categorizedElements = data.categorized?.flatMap(category => category.elements) || []
            const uncategorizedElements = data.uncategorized?.elements || []
            const allElements = [...categorizedElements, ...uncategorizedElements]
            
            if (allElements.length === 0) return 0
            
            const conceptElements = allElements.filter(element => element.is_concept === 1)
            if (conceptElements.length === 0) return 0
            
            return conceptElements.every(element => element.remove_continue_btn === "1") ? 1 : 0
        },
        formatMessage(msg) {
            return msg.replace(/\n/g, '<br>')
        },
        conceptSelected(id, name) {
            this.dataSelected({
                'concept_id': id,
                'concept_name': name,
            })
        },
        eventSelected(id, name) {
            document.location.href = 'https://bord-booking.dk/online_events.php?company='+this.restaurantData['company_id']+'&event='+id;
        },
        toggleCategory(categoryId) {
            this.$set(this.openCategories, categoryId, !this.openCategories[categoryId])
        },
    },
    created() {
    }
}
</script>


<style>
.concept_container {
    display: flex;
    
}

.image {
  flex: 0 0; 
}

.concept-image {width:150px;}

.text_container {
  flex: 1;
  /* padding-left: 20px; */
  padding: 0 0 0 20px;
  text-align: left;
  word-break: break-word;
}

.concept_title {
  font-weight: bold;
  font-size: 1.5em;
}

.concept_description {
  margin-top: 10px;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

@media screen and (max-width: 450px) {
    .concept-image {width:100px;}

    .concept_title {
        font-size: 1.2em;
    }
}

.categories-container {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 20px; */
}

.category-accordion {
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    /* background-color: #f5f5f5; */
    cursor: pointer;
}

.category-header h3 {
    margin: 0;
}

.accordion-icon {
    font-size: 20px;
    font-weight: bold;
    color: black !important;
}

.category-content {
    padding: 15px;
}

.concepts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.concept-card img {
    object-fit: cover;
}

.uncategorized-section {
    margin-top: 30px;
    padding-top: 20px;
}
</style>


