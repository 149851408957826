<template>
    <div>        
        <p v-html="headerMessage"></p>
        <label for="bookingid" class="namelbl">{{ lang.company['bookingnummer'] }}</label>

        <div class="input_container">
            <div class="wrap-input100 validate-input" :class="bookingNumberClassError">
                <input v-model="bookingNumber" id="bookingid" class="input100" type="text" name="name" :placeholder="lang.company['bookingnummer']">
                <span class="focus-input100 "></span>
                <label class="label-input100" for="name">
                    <i class="font_awsome_color fas fa-user-tie m-b-2 "></i>
                </label>
            </div>

            <div class="contact100-div validate-form">
                <label for="phone" class="namelbl">{{ lang.company['dit_mobilnummer'] }}</label>
                <span 
                    class="ui-icon-info ui-icon" 
                    style="display:inline-block;" 
                    :title="lang.company['mobil_bruges_ikke_tekst']"
                >
                </span>
                <vue-tel-input 
                    class="wrap-input100" 
                    :class="mobilePhoneClassError"
                    @validate="validateNumber" 
                    :defaultCountry='preferredCountries[0] ? preferredCountries[0] : "dk"'
                    :inputOptions="{ required: true, placeholder: this.lang.company['placeholder_phone'] }"
                    :autoDefaultCountry="false"
                    :preferredCountries="preferredCountries"
                />
            </div>

            <div 
                v-if="errorMessage !== ''"
                class="error" 
                style="display: block;"
            >
                <br>
                {{ errorMessage }}
            </div>
        </div>


        <span 
            class="button_confirmOptions ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            style="margin-top: 20px;"
            aria-disabled="false"
            @click="checkForm"
        >
            <span class="ui-button-text">{{ lang.company['gennemfoer_afbestilling'] }}</span>
        </span>


        <p class="book-table" v-html="bookTableMessage" style="clear:both;"></p>
        
    </div>
</template>

<script lang="js">
import { VueTelInput } from 'vue-tel-input';

export default {
    name: 'BookingInformation',
    data() {
        return {
            formError: '',

            bookingNumber: '',
            bookingNumberClassError: '',

            phoneObject: '',
            mobilePhoneClassError: '',
        }
    },
    props: ['restaurantID', 'restaurantData', 'dataSelected', 'error'],
    components: {
        VueTelInput
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        errorMessage() {
            if (this.formError !== '') return this.formError
            if (this.error !== '') {
                this.bookingNumberClassError = 'alert-validate'
                this.mobilePhoneClassError = 'alert-validate'
                return this.error
            }
            this.bookingNumberClassError = ''
            this.mobilePhoneClassError = ''
            return ''
        },
        bookingLink() {
            const baseURL = window.location.href.split('?')[0]
            let queryParams = ''

            if (this.$route.query.hasOwnProperty('lang')) {
                const language = this.$route.query.lang
                queryParams += `?lang=${language}`
            }
            if (this.$route.query.hasOwnProperty('restaurantId')) {
                const restaurantId = this.$route.query.restaurantId
                if (queryParams === '') {
                    queryParams += "?"
                } else {
                    queryParams += "&"
                }

                queryParams += `restaurantId=${restaurantId}`
            }
            return baseURL + queryParams
        },
        headerMessage() {
            let translatedMessage = this.lang.company['indtast_dine_bookingoplysninger']
            if (translatedMessage === undefined) {
                translatedMessage = ""
            } else {
                translatedMessage = translatedMessage.replace("{PHONE}", this.restaurantData.company_phone);
            }
            return translatedMessage
        },
        bookTableMessage() {
            let message = this.lang.company['reserver_et_bord']
            let link = this.bookingLink
            if (message === undefined) {
                message = ""
            } else {
                message = message.replace("online_booking.php?restaurantId={ID}", link)
            }
            return message
        },
        preferredCountries() {
            return this.restaurantData.preferred_countries ? this.restaurantData.preferred_countries : ["dk", "no", "se"]
        }
    },
    methods: {
        validateNumber(phoneObject) {
            this.phoneObject = phoneObject
        },
        checkForm(e) {
            e.preventDefault()

            const validForm = this.validateForm()
            if (!validForm) {
                this.formError = this.lang.company['ugyldig_bookingnummer_eller_telefon']
            }else {
                this.formError = ''
                this.dataSelected({
                    'bookingNumber': this.bookingNumber,
                    'mobileNumber': this.phoneObject.number
                })           
            }
        },
        validateForm() {
            var validForm = true
            if (this.bookingNumber == '') {
                validForm = false
                this.bookingNumberClassError = 'alert-validate'
            }else {
                this.bookingNumberClassError = ''
            }

            if (!this.phoneObject.valid || this.phoneObject == '' || typeof this.phoneObject.number === 'undefined' || this.phoneObject.valid == false) {
                validForm = false
                this.mobilePhoneClassError = 'alert-validate'
            }else {
                this.mobilePhoneClassError = ''
            }

            return validForm
        }
    },
}
</script>

